.dashboard-layout {
    position: relative;
    display: flex;
    height: 100vh;
    flex: 1;
}

.ql-snow .ql-editor img {
    display: block;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.ql-editor .ql-align-center {
    text-align: center;
}

.dashboard-layout>header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-bottom: 1px solid #DDd;
}

aside {
    margin-top: 70px; 
    display: flex;
    min-height: calc(100vh - 70px);
    flex: 1;
}
.dashboard-child {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.dashboard-child.sidepanel {
    width: 45vw;
}

.sidepanel-parent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #939393;
    text-align: center;
    margin-top: 10px;
}

.sidepanel-icons {
    width: 24px;
    height: 24px;
    margin: 10px;
    cursor: pointer;
}

.sidepanel-item {
    color: #939393;
    cursor: pointer;
    padding: 0px 10px;
}

.sidepanel-item div {
    font-size: 6px;
}

.sidepanel-item.active {
    color: #0B82A2;
    border-right: 3px solid #0B82A2;
}

header {
    display: flex;
    z-index: 2;
}

img.DashBoard-toggle {
    width: 24px;
    height: 15px;
    color: black;
}

.DashBoard-Logo {
    width: 187px;
    padding: 5px 0px;
    text-align: center;
}

.DashBoard-Logo>img{
    width:100%;
    height: 50px;
}

.DashBoard-Notification {
    max-width: 20px;
    max-height: 20px;
}

img.DashBoard-Photo {
    max-width: 20px;
    margin-right: 18px;
    margin-left: 25px;
    border-radius: 50%;
}

.Dashboard-brand {
    width: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-side-header {
    display: flex;
    align-items: center;
    width: 100%;
    /* gap: 107px; */
    /* justify-content: space-between; */
    padding: 5px;
}

.topbar-icons{
    display: flex;
    gap: 20px;
}

.account-btn .btn.btn-white{
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-dashborad {    
    width: 100px;
    border-radius: 30px;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 9px;
    padding-right: 9px;
}

.select-dashborad select {
    border: none;
    outline: none;
    width: 100%;
}

.search-dashborad {
        display: flex;
        align-items: center;
        width: 100%;
        border-radius: 30px;
        gap: 20px;
        max-width: 500px;
        flex-shrink: 30;
        margin-left: 30px;
        margin-right: 50px;
        height: 45px;
        padding-left: 12px;
        padding-right: 12px;
        border:1px solid #ccc;
}

.dashboard-after-search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    margin-right: 30px;
}

.menu-right {
    padding-top: 12px;

}

.input-control {
    border: none;
    outline: none;
    width: 100%;
}

.input-search {
    border: none;
    outline: none;
    width: 100%;
}

.status_bar {
    /* width: 100px; */
    /* border-radius: 25px; */
    /* padding: 2px 10px; */
    width: 100px;
    height: 17px;
    border-radius: 10px;
}

.progress-bar {

    background-color: #0B82A2;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 50%;
}

.Active_name {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: #939393;
}

.input-control:focus {
    border: none;
}

.doctor-name {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.doctor-account {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #EF6C00;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-docotr {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar-main {
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 86px;
    padding-top: 18px;
}

.sidebar-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.sidebar-name {
    margin-top: 10px;
    margin-bottom: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
}

.sidebar-child {
    box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.25);
    width: 187px;
    margin-top: 70px;
    border-right: 1px solid #DDD;
}

.sidebar-child h5 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #408083;
    padding-top: 33px;
    padding-left: 21px;
    padding-right: 58px;
    margin-bottom: 0px;
}

.Configiration-list {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin: 10px;
    text-overflow: ellipsis;
    width: 94%;
    white-space: nowrap;
    overflow: hidden;
}

/* .Configiration-list:hover a .list-name {
    color: #00769B;
} */


.dashboard-content {
    padding-left: 12px;
    padding-right: 10px;
    padding-top: 12px;
    /* background: #EEF7FF; */
    min-height: calc(100vh - 500px);
    overflow-y: auto;
    margin-right: 30px;
    margin-top: 60px;
    flex: 15;
    margin-left: 15px;
}

.navitem:hover,
.navitem.active {
    border-left: 5px solid #00769B;
    margin-left: 0px;
}

.navitem:hover .sidebar-name .navitem.active .sidebar-name {
    color:#408083;
}

.navitem.active {
    color: #408083;
}

.navitem {
    margin-left: 5px;
}

.dashboard-header {
    display: flex;
    background: #DDEFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    margin-top: 12px;
    height: 81px;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.dashboard-footer1 {
    display: flex;
    background: #EEF7FF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.month-selector {
    flex: 0.2;
}

h5.dashboard-subtitle {
    flex: 1;
    margin-bottom: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #00769B;
    padding-top: 8px;
}

h5.dashboard-subtitle1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #00769B;
}

/* h5.dashboard-subtitle2 {
    flex: 1;
    margin-bottom: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #00769B;
    padding-top: 8px;
    margin-left: 26px;
} */

.Configiration-list:hover,
.Configiration-list.active {
    background: #eeeeee;
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 25%);
    border-radius: 30px;
}

.Configiration-list.active {
    background: #408083;
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 25%);
    border-radius: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 94%;
    margin: 6px;
}

.configiration-icon {
    max-width: 14px;
    max-height: 14px;
}

.colorChange {
    color: #fff !important;
    text-decoration: none;
}

.NoColorChange {
    color: #5C5C5C;
    text-decoration: none;
}

.list-name {
    margin-left: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}

.navitem {
    text-align: center;
    cursor: pointer;
}

.container-form {
    background: #fff;
    padding: 12px 30px 0px 30px;
    border-radius: 5px;
}

button.btn.btn-white {
    background: #fff;
    color: #408083;
    border-radius: 4px;
    padding: 12px 54px 13px 54px;
}

button.btn.btn-white1 {
    background: #fff;
    color: #00769B;
    border-radius: 4px;
    border: 1px solid #00769B;
    padding: 12px 54px 13px 54px
}

button.btn.btn-white:hover {
    background: #408083;
    color: #fff;
}

button.btn.btn-white1:hover {
    background: #408083;
    color: #fff;
}

select.btn.btn-white:hover {
    background: #408083;
    color: #fff;
    border-radius: 4px;
}

button.btn.btn-primary {
    background: #408083;
    border-radius: 4px;
    min-width: 143px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

select.btn.btn-primary {
    background: #408083;
    border-radius: 4px;
    min-width: 143px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.btn.btn-primary:hover {
    color: #408083;
    background: #fff;
    border: 1px solid #00769B;
    border-radius: 4px;
}

.dashboarlist-left-entries {
    display: flex;
    gap: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #5C5C5C;
}

.dashboard-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 22px;
    padding-bottom: 21px;
    background: #F9FCFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}

.dashboard-list-filter-main {
    margin-right: 28px;
    cursor: pointer;
}

.dashboard-list-filters {
    width: 18px;
    margin-left: 28px;
    height: 18px;
}

.dashboard-list-number {
    padding-right: 44px;
    border: 0.5px solid #CACACA;
    border-radius: 1px;
}

.dashboard-table-head {
    border-bottom: 0.3px solid #5C5C5C;
    font-family: 'Inter';
    padding-top: 16px;
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #1D273B;
}

.dashboard-tabe-data-actions {
    max-width: 16px;
    max-height: 16px;
    cursor: pointer;
}

.dashboard-tabe-data-actions:hover {
    transition: 0.4s;
    background-color: rgba(0, 0, 0, 0.54);
    border-radius: 50%;
}

.tick-size {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.dashboard-table-list-main {
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1), -2px 0px 2px rgba(0, 0, 0, 0.1), 0px -2px 2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.search-block {
    width: 278px;
    height: 45px;
    margin-left: auto;
    margin-right: 20px;
}

.insti_checkbox {
    margin: 0px 20px 30px 20px;
    ;
}

.insti-label {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 16px;
}

.institution-name {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
}

.centered-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.horizontal-line {
    flex-grow: 1;
    height: 1px;
    background-color: #d1d1d1;
    margin: 0 10px;
}

.insti-button {
    display: flex;
    align-items: center;
    width: 143px;
    height: 45px;
    margin-left: auto;
    margin-right: 20px;
}

.account-btn .btn.btn-white{
    border-radius: 10px;
    border: 0.5px solid var(--Color-10, #939393);
    background: var(--Color-8, #FFF);
}

.printing-preference {
    margin: 0px 20px 30px 20px;
}

.quill-table {
    margin-top: 30px;
}

.printing-image {
    margin: 34px 0px;
}

.quill-box {
    margin-left: 30px;
}

.arrow-line {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: #000;
}

.arrow-line::before,
.arrow-line::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
}

.arrow-line::before {
    border-width: 10px 15px 10px 0;
    border-color: transparent #000 transparent transparent;
    left: -2px;
    top: -9px;
}

.arrow-line::after {
    border-width: 10px 0 10px 15px;
    border-color: transparent transparent transparent #000;
    right: -2px;
    top: -9px;
}

p.line-label {
    position: absolute;
    margin: 0;
    top: -9px;
    background: #fff;
    left: 50%;
    padding: 0px 20px;
}

.vertical-line {
    transform: rotate(270deg);
    transform-origin: left;
    position: absolute;
    bottom: 181px;
    width: 68%;
}

.excel-button {
    display: flex;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 30px;
}

/* footer{
    width:100%;
    background: #C7E5FF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
     z-index: 2; 
     position:fixed;
     bottom:0;
     color: #057697;
     font-weight: 400;
     font-size: 12px;
     line-height: 15px;
     text-align: center;
} */